<template>
  <a-tree-select
    v-model="selectedValue"
    class="tree-select"
    :treeData="categoryTree"
    treeCheckable
    :showCheckedStrategy="SHOW_ALL"
    :placeholder="$t(`filter.treeFilterPlaceholders.${optionsModel}`)"
    allowClear
    :size="controlsSize"
    @change="change"
  />
</template>

<script>
import { TreeSelect } from 'ant-design-vue';
import TABLE_QUERY from '@/queries/table';
import store from '@/store';

const findNode = (value, nodes) => {
  let result = null;

  nodes.some((node) => {
    if (node.value === value) {
      result = node;
      return true; // Останавливает выполнение some, если найден узел
    }
    if (node.children) {
      result = findNode(value, node.children);
      return !!result; // Продолжает искать, если в дочерних узлах что-то найдено
    }
    return false;
  });

  return result;
};

const SHOW_ALL = TreeSelect.SHOW_PARENT;

export default {
  name: 'CategoryTreeConfig',

  props: {
    controlsSize: {
      type: String,
      default: 'large',
    },
    optionsModel: {
      type: String,
      required: true,
    },
    categorySelected: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      showConfig: false,
      SHOW_ALL,
      selectedValue: [],
      optionsList: [],
    };
  },

  computed: {
    activeSidebarItemCode() {
      return store.state.activeSidebarItem.code;
    },
    meta() {
      return store.state.meta.components[this.activeSidebarItemCode];
    },
    categoryTree() {
      if (!this.optionsList) return [];
      const list = this.optionsList;

      const map = {};
      let node;
      const roots = [];
      let i;

      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentId) {
          // if you have dangling branches check that map[node.parentId] exists
          list[map[node.parentId]].children.push(node);
        } else {
          roots.push(node);
        }
      }

      return roots;
    },
  },

  watch: {
    selectedValue() {
      let extendedList = [];

      this.selectedValue.forEach((el) => {
        const currentNode = findNode(el, this.categoryTree);
        const ggggggg = this.getAllDescendants(el, this.categoryTree);
        extendedList = [
          ...extendedList,
          { title: currentNode.title, value: currentNode.value },
          ...ggggggg,
        ];
      });
      extendedList = extendedList.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      if (this.selectedValue.length !== extendedList.length) {
        this.$emit('selectCategory', extendedList, this.optionsModel);
      }
    },
  },
  async mounted() {
    await this.updateOptionsList();

    this.selectedValue = this.categorySelected.map((category) => category.value);
  },
  methods: {
    async updateOptionsList() {
      const { data, error } = await this.$apollo.query({
        ...TABLE_QUERY,
        variables: {
          type: this.optionsModel,
          page: 0,
          pageSize: 999,
          filters: [],
          orders: [],
        },
        loadingKey: 'loading',
        fetchPolicy: 'network-only',
      });

      if (error) {
        this.emitError(this.$t('entity.error.getTable'), error.message);
      }

      this.optionsList = data?.table.documents.map((el) => ({
        ...el.data,
        id: `${this.optionsModel}:${el.id}`,
        value: `${this.optionsModel}:${el.id}`,
        key: `${this.optionsModel}:${el.id}`,
        parentId: el.data?.parent?.value,
      }));
    },
    getAllDescendants(nodeValue, treeData) {
      const node = findNode(nodeValue, treeData);
      if (!node) return [];

      const descendants = [];
      const stack = [...(node.children || [])];

      while (stack.length) {
        const child = stack.pop();
        descendants.push({ title: child.title, value: child.value });
        if (child.children) {
          stack.push(...child.children);
        }
      }

      return descendants;
    },

    change(values, labels) {
      const transformedNodes = values.map((value, idx) => ({ value, title: labels[idx] }));
      this.$emit('selectCategory', transformedNodes, this.optionsModel);
    },
  },
};
</script>

<style lang="scss">
.tree-select {
  width: 100%;
}
</style>
