import gql from 'graphql-tag';
import store from '@/store';
import { metaQueryParser } from '@/helpers';

export default {
  fetchPolicy: 'network-only',
  query: gql`
    query Meta($type: String) {
      meta(type: $type) {
        version
        modelMutationsAllowed
        handbooks
        components
        embeds
        processes
        entities {
          name
          pk
          publishable
          fields {
            config {
              multiple
              url
            }
            name
            renderer
            types
            required
          }
          actions {
            name
            type
          }
          config
        }
      }
    }
  `,
  update({ meta }) {
    return meta && metaQueryParser.prepareMeta(meta, store.state.user);
  },
};
